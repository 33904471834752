/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */
// eslint-disable-next-line @typescript-eslint/no-redeclare
export var ActivityType = {
    comment: 'comment',
    payment: 'payment',
    invoice: 'invoice',
    callcenter: 'callcenter',
    dispute: 'dispute',
    pause: 'pause',
    lawyer: 'lawyer',
    bailiff: 'bailiff',
    reminders: 'reminders',
    preference: 'preference',
    formal_notice: 'formal_notice',
};

/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */
// eslint-disable-next-line @typescript-eslint/no-redeclare
export var Role = {
    admin: 'admin',
    callcenter: 'callcenter',
    lawyer: 'lawyer',
    bailiff: 'bailiff',
    formal_notice: 'formal_notice',
    additional_reminder: 'additional_reminder',
    all_invoices: 'all_invoices',
    follow_up: 'follow_up',
};

/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */
// eslint-disable-next-line @typescript-eslint/no-redeclare
export var TrackedType = {
    Invoice: 'Invoice',
    CreditNote: 'CreditNote',
    Debtor: 'Debtor',
    Question: 'Question',
    CompanyDetail: 'CompanyDetail',
    Task: 'Task',
};
